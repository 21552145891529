.reviews-block {
  background: $black;
  overflow: hidden;
  position: relative;
  z-index: 9;

  .background-image {
    background: url('../../assets/img/bg_reviewblock.png') no-repeat center bottom fixed;
    background-size: cover;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 8;
    width: 100%;
  }

  .slider {
    background: transparent;
    margin: 0 !important;

    .slick-arrow {
      background: none;
      height: 40px;
      width: 40px;

      &:before {
        color: $white;
        font-size: 20px;
      }

      &:hover {
        background: none;


        &.slick-prev {
          &:before {
            color: $primary-color;
            content: '\f077' !important;

            @include breakpoint(small down) {
              content: '\f053' !important;
            }

          }
        }

        &.slick-next {

          &:before {
            color: $primary-color;
            content: '\f078' !important;

            @include breakpoint(small down) {
              content: '\f054' !important;
            }
          }
        }

      }

      &.slick-prev {
        left: inherit;
        right: 0;
        top: 35%;

        @include breakpoint(small down) {
          left: 0;
          right: initial;
          top: 45%;
        }

        &:before {
          content: '\f077' !important;
          padding-right: 2em;

          @include breakpoint(small down) {
            content: '\f053' !important;
            padding-right: 0;
          }
        }
      }

      &.slick-next {
        top: 55%;

        @include breakpoint(small down) {
          top: 45%;
        }

        &:before {
          content: '\f078' !important;
          padding-left: 0;
          padding-right: 2em;

          @include breakpoint(small down) {
            content: '\f054' !important;
            padding-right: 0;
          }
        }
      }

    }

  }

  .about {
    background: $black;
    padding: 35px 0;
    position: relative;
    z-index: 9;
    display: table;
    width: 100%;

    @include breakpoint(small down) {
      text-align: center;
    }

    .about-wrap {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }

    h6 {
      color: $white;
      margin: 0;
    }

    .score {
      color: $primary-color;
      font-size: 80px;
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      line-height: 120%;

      @include breakpoint(medium up) {
        font-size: 120px;
      }

    }

    p {
      color: $white;
      margin: 0;
      a {
        color: $primary-color;
        display: block;
        text-decoration: underline;

        &:hover, &:focus {
          color: $secondary-color;
          text-decoration: none;
        }

      }
    }

  }

}

.reference-slider {
  .slick-slide {
    &:focus {
      outline: none !important;
    }

  }

  &.slick-slider {
    .slick-arrow {
      background: #ef4035;
      border: none;
      border-radius: 0;
      bottom: inherit;
      height: 30px;
      width: 30px;
      top: 40%;
      transition: all 0.35s ease 0s;
      z-index: 8;

      @include breakpoint(small down) {
        top: 28%;
      }

      &.slick-disabled {
        display: none !important;
      }

      &:hover {
        background: $black;
        border: none;

        &.slick-prev, &.slick-next {

          &:before {
            color: $white;
            content: '\f104' !important;
          }

        }

        &.slick-next {

          &:before {
            content: '\f105' !important;
          }

        }

      }

    }

    .slick-prev, .slick-next {
      transform: none;

      @include breakpoint(xxxlarge up) {
        left: -60px;
      }

      &:before {
        color: $white;
        content: '\f104' !important;
        display: block;
        font-family: FontAwesome;
        font-size: 22px;
        font-weight: bold;
        opacity: 1;
        padding-right: 4px;
        transition: all 0.3s ease 0s;
      }

    }

    .slick-next {
      left: inherit;

      @include breakpoint(xxxlarge up) {
        right: -60px;

      }

      &:before {
        content: '\f105' !important;
        font-family: FontAwesome;
        padding-left: 4px;
        padding-right: 0;
      }

    }
  }
}

.review {
  color: $white;
  padding: 1em 0;
  text-align: center;

  @include breakpoint(medium up) {
    padding: 2em;
  }

  @include breakpoint(large up) {
    padding: 2em 3em;
  }

  @include breakpoint(xlarge up) {
    padding: 2em 6em;
  }

  &.slide {
    color: $black;
    padding: 0 2.5em;
    text-align: left !important;

    @include breakpoint(xxlarge up) {
      padding: 0;
    }

    .from-quote {
      @include breakpoint(large up) {
        margin-bottom: 30px;
      }

      @include breakpoint(xlarge up) {
        font-size: 25px;
      }
    }

    .image {
      img {
        min-height: 500px;
      }
    }

    .from-on {
      color: #ba844a;
    }

    .image-wrapper {
      display: block;
      overflow: hidden;
      margin-bottom: 2em;
      position: relative;

      @include breakpoint(small down) {
        margin: 0 auto 1em;
        width: 70%;
      }

      .frame {
        display: block;
        left: 0;
        overflow: hidden;
        position: relative;
        top: 0;
        z-index: 9;
        width: 100%;
      }

      .image {
        display: block;
        height: 90%;
        left: 2%;
        position: absolute;
        top: 5%;
        width: 96%;
      }

    }

  }

  .rating {
    display: block;
    margin-bottom: 1em;
    text-align: center;

    @include breakpoint(large up) {
      margin-bottom: 20px;
    }

    @include breakpoint(xlarge up) {
      margin-bottom: 40px;
    }

    i {
      color: $primary-color;
      font-size: 28px;
    }
  }

  .from-on {
    color: #c3a385;
    font-size: 15px;
  }

  .from-quote {
    display: block;
    font-size: 16px;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    margin-bottom: 1em;
    text-transform: uppercase;

    @include breakpoint(medium up) {
      font-size: 20px;
      margin-bottom: 20px;
    }

    @include breakpoint(large up) {
      margin-bottom: 40px;
    }
  }

}
.overview-reviews {
  .review {
    background: $black;
    margin-bottom: 10px;
    padding-left: 35px;
    padding-right: 35px;
    text-align: left;

    @include breakpoint(small down) {
      border-bottom: 2px solid $secondary-color;
      margin-bottom: 0;
      padding-left: 15px;
      padding-right: 15px;
    }

    .rating {
      text-align: left;
    }

    .from-quote {
      margin-bottom: 0.5em;
    }
  }
}