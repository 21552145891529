.column-block {

  .title {
    h2 {
      margin-bottom: 0.3em;
    }

    h4 {
      font-weight: 400;

      span {
        color: $primary-color
      }

    }

    a {
      text-decoration: none;

      &[href^="tel"] {
        color: $primary-color !important;
      }

    }

  }

  .content-block {
    background: $white;
    display: inline-block;
    margin-bottom: 10px;
    padding: 30px 35px;

    @include breakpoint(small down) {
      padding: 15px 15px;
    }

    .text {
      .input-group {
        float: left;
        margin-top: 25px !important;
        width: 70%;

        @include breakpoint(xlarge down) {
          width: 100%;
        }

        label {
          padding-top: 0;
        }
      }
    }

    .button-wrap {
      margin-top: 20px;

      @include breakpoint(medium up) {
        float: right !important;
      }

      a {
        &:after {
          background-image: url('../../assets/img/pijltje_omlaag.svg');
          background-size: 17px 11px;
          background-position: right center;
          content: "";
          display: block;
          height: 11px;
          position: absolute;
          right: 15px;
          width: 15px;
          top: 20px;
          z-index: 9;
          transition: all 0.3s ease 0s;
        }

      }

      &:after {
        display: none;
      }
    }

  }

  .actions {
    float: left;
    display: inline-block;
    margin: 1em 0;

    @include breakpoint(medium up) {
      float: right;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        float: left;
        margin-right: 2em;

        a {
          color: $primary-color;
          font-family: 'Oswald', sans-serif;
          font-weight: 700;
          font-size: 16px;
          text-decoration: none;
          text-transform: uppercase;

          &:hover {
            color: #e61f12;
          }

        }

      }

    }
  }

  .button-wrap {
    float: left;
    position: relative;
    width: 100%;

    @include breakpoint(medium up) {
      float: right;
      width: auto;
    }

    input {
      margin-bottom: 0;

      &.arrow {
        padding-right: 40px;
      }
    }

    &:after {
      background-image: url('../../assets/img/pijltje.svg');
      background-size: 11px 15px;
      background-position: right center;
      content: "";
      display: block;
      height: 15px;
      position: absolute;
      right: 20px;
      width: 11px;
      top: 17px;
      z-index: 9;
      transition: all 0.3s ease 0s;
    }

  }

  .accordion {
    background: transparent;

    .button-wrap {

      a {
        &:after {
          background-image: url('../../assets/img/pijltje_omlaag.svg');
          background-size: 17px 11px;
          background-position: right center;
          content: "";
          display: block;
          height: 11px;
          position: absolute;
          right: 15px;
          width: 15px;
          top: 20px;
          z-index: 9;
          transition: all 0.3s ease 0s;
        }

        &.prev {
          &:after {
            background-image: url('../../assets/img/pijltje_omhoog.svg');
          }

        }

      }

      &:after {
        display: none;
      }
    }

  }

  .multi-step-checkout-process-step {
    margin-bottom: 3rem;
  }
  .multi-step-checkout-process {

    .side-order {
      padding: 0 0 35px;

      h5 {
        font-weight: 400;
        margin-bottom: 15px;
        margin-left: 0;
      }

      .image {
        height: 175px;

        @include breakpoint(small down) {
          float: left;
          height: 130px;
          width: 30%;
        }

      }

      input {
        &[type='submit'], &[type='button'] {
          background: none;
          border: none;
          color: $primary-color;
          cursor: pointer;

          &:hover {
            color: #e61f12;
          }

        }

      }

      select {
        background-color: #f7f7f7;
        box-shadow: inset 2px 2px 2px 0px rgba(222, 222, 222, 1);
        border: none;
        margin-bottom: 0.5em;

        @include breakpoint(medium up) {
          margin-bottom: 1em;
        }
      }

    }

    .accordion-title {
      color: darken($dark-gray, 30%);
      font-size: 1.25rem;
      padding-left: 35px;
      transition: color 0.5s ease;
      border: none;
      font-family: 'Droid serif', serif;

      @include breakpoint(small down) {
        padding-left: 25px;
        font-size: 18px;
      }

      &:hover,
      &:active,
      &:focus {
        color: darken($dark-gray, 40%);
        transition: color 0.5s ease;
        background: none;
      }
    }

    :last-child:not(.is-active) > .accordion-title {
      border: none;
    }

    .accordion-item {
      background: #362f2d;
      border: 3px solid #362f2d;
      margin-bottom: 10px;

      form {
        margin-top: 3em;
      }

      &.is-active {
        background: $white;
        border: 3px solid #ef4035;

        .accordion-title {
          font-size: 30px;
          padding-top: 30px;
          padding-bottom: 5px;
          transition: all 0.3s ease 0s;

          @include breakpoint(small down) {
            font-size: 18px;
          }
        }

      }

      .accordion-title {
        color: $secondary-color;
        font-family: 'Oswald', sans-serif;
        font-weight: 700;
        padding: 12px 35px;
        text-transform: uppercase;

        @include breakpoint(medium down) {
          padding: 12px 15px;
        }
      }

      .accordion-title::before {
        //display: none;
        right: 35px;
        top: 45%;

        @include breakpoint(medium down) {
          right: 15px;
        }
      }
    }

    .accordion-content {
      border: none;
      padding: 0 35px 35px 35px;

      @include breakpoint(medium down) {
        padding: 0 15px 25px;
      }

      .order-block {
        //position: relative;
        z-index: 9;
        .side-order {
          .grid-x {
            .cell {
              &:first-of-type {
                .item {
                  padding-left: 0;
                }
              }
            }
          }
        }
      }

    }
    :last-child > .accordion-content:last-child {
      border-bottom: none;
    }
  }

  .multi-step-checkout-step-title-subheader {
    color: lighten($dark-gray, 10%);
    font-size: 0.9rem;
    display: inline-block;
    margin-left: 10px;
    font-family: 'Open sans', serif;
  }

  .multi-step-checkout-step-subheader {
    color: darken($dark-gray, 30%);
    font-size: 0.9rem;
    font-weight: 600;
    font-family: 'Open sans', sans-serif;
  }

  .multi-step-checkout-step-subdesc {
    color: lighten($dark-gray, 0%);
    font-size: 0.9rem;
  }

  .multi-step-checkout-shipping-option {
    margin-bottom: 10px;
  }

  .multi-step-checkout-step-section {
    border-bottom: 1px dashed $light-gray;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .multi-step-checkout-shipping-option-selection {
    position: absolute;
    margin-top: 0.2rem;
  }

  .multi-step-checkout-shipping-option-title {
    display: block;
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin-left: 1.6rem;
    margin-bottom: 0.8rem;
  }

  .multi-step-checkout-shipping-option-desc {
    font-size: 0.9rem;
    color: $dark-gray;
    margin-left: 25px;
    margin-top: -10px;
  }

  .multi-step-checkout-shipping-cost {
    text-align: right;
  }

  .multi-step-checkout-form-divider {
    border-bottom: 1px dashed $light-gray;
  }

  .multi-step-checkout-form {
    input {
      background: lighten($light-gray, 7%);
    }
    .button-wrap .final {
      float: right;
      margin-left: 1em;

      @include breakpoint(medium down) {
        float: initial;
        margin-top: 1em;
        margin-left: 0;
        margin-bottom: 1em;
      }

      input {

        @include breakpoint(small down) {
          display: block;
          width: 100%;
          text-align: left;
        }
        &.button {
          background: $primary-color;
        }
      }
    }
  }

  .multi-step-checkout-enews-sign-up {
    label {
      position: relative;
      margin-left: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .multi-step-checkout-enews-sign-up-checkbox {
    position: absolute;
    margin-top: 0.4rem;
  }

  .multi-step-checkout-credit-card-info {
    label {
      span {
        color: lighten($dark-gray, 10%);
      }
    }
  }

  .multi-step-checkout-payment-icons {
    list-style: none;
    margin-left: 0;
    margin-bottom: 1rem;

    li {
      display: inline;
    }
  }

  .multi-step-checkout-billing-address {
    .multi-step-checkout-billing-address-list {
      list-style: none;
      margin-left: 1.5rem;
      margin-top: 1rem;
      margin-bottom: 0.8rem;
      color: $dark-gray;
      font-size: 0.9rem;
    }

    .multi-step-checkout-billing-address-checkbox {
      position: absolute;
      margin-top: 0.4rem;
    }

    label {
      margin-left: 1.5rem;
    }
  }

  .multi-step-checkout-create-account {
    margin-top: 2rem;

    @include breakpoint(small down) {
      margin-top: 0.5rem;
    }

    .create-account-desc {
      font-size: 0.9rem;
      color: $dark-gray;
    }
  }

  .multi-step-checkout-logo {
    width: 118px;
    height: 38px;
  }

  .multi-step-checkout-arrow {
    height: 13px;
    width: 13px;
  }

}