.usp-block {
  margin: 0 0 1em;

  @include breakpoint(xxlarge up) {
    margin: 0 0 2em;
  }

  .icon {
    margin: 0 1em 0 0;

    svg {
      height: 20px;
      width: 25px;
    }

    polygon {
      fill: #ba844a;
    }

  }

  .title {
    color: $white;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    font-size: 15px;
    font-style: normal;
    padding-left: 0.5em;
    text-transform: uppercase;
    text-align: left;

    @include breakpoint(medium up) {
      padding-left: 0;
      font-size: 16px;
    }

    @include breakpoint(large up) {
      font-size: 15px;
    }

    @include breakpoint(xlarge up) {
      font-size: 16px;
    }
  }

  .usp {
    padding: 0;

    @include breakpoint(xxlarge up) {
      padding: 0 3em;
    }
  }

}
.home {
  .usp-block {
    margin: 2em 0 0;
  }
}
.sticky.is-stuck {
  top: 118px !important;
  margin-top: 0 !important;
  background: #000;
  z-index: 999;
  padding-top: 0.5em;

  @include breakpoint(medium down) {
    top: 144px !important;
  }

  .usp-block {
    margin: 0 !important;
  }

  &:before {
    content: '';
    background: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -90%;
    display: inline-block;
    top: 0;
    z-index: -1;
  }

  &:after {
    content: '';
    background: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    right: -90%;
    display: inline-block;
    top: 0;
    z-index: -1;
  }

}
#order-overview-block {
  &.sticky.is-stuck {

    &:before {
      display: none !important;
    }

    &:after {
      display: none !important;
    }
  }
}
