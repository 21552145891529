header {
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 9999;
  transition: all 0.3s ease 0s;
  top: 0;
  width: 100%;

  @include breakpoint(small down) {
    background: $black;
  }

  .site-navigation {
    position: relative;

    @include breakpoint(medium up) {

      &:after {
        content: '';
        display: block;
        height: 1px;
        left: 0;
        margin-left: 220px;
        position: absolute;
        top: 103px;
        background: rgba(187, 133, 74, 0.3);
        z-index: 7;
        right: 0;
      }

      &:after {
        left: 0;
      }

    }

    .nav-parts {
      margin-top: 27px;
      transition: all 0.35s ease 0s;

      @include breakpoint(medium up) {
        margin-top: 25px;
      }

      .shopping-cart {
        color: $white;

        &:hover {
          color: $primary-color;
        }

      }

      .button {
        border-bottom: none;
        padding-bottom: 18px;
        color: $black;
        //text-decoration: underline;

        &.arrow {
          padding-right: 45px;
          width: 100%;

          &:after {
            background-image: url('../../assets/img/pijltje_dark.svg');
          }

          @include breakpoint(large up) {
            padding-right: 80px;
          }
        }

      }

    }

  }

  .logo {
    margin-top: 10px;
    width: 200px;
    transition: all 0.35s ease 0s;

    @include breakpoint(small down) {
      width: 100px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    a {
      position: relative;
      width: 200px;

      @include breakpoint(small down) {
        width: 100px;
      }

      img, svg {
        position: relative;
        z-index: 8;
        transition: all 0.35s ease 0s;
        height: 128px;
        width: 200px;

        @include breakpoint(large down) {
          width: 165px;
        }

        @include breakpoint(small down) {
          height: 64px;
          width: 100px;
        }
      }

    }

  }

  &.shrink {
    background: $black;

    @include breakpoint(large up) {
      height: auto;

      .site-desktop-title {
        margin: 1.5em 0 0 0 !important;
      }

      .breadcrumbs {
        top: 90px;
        transition: all 0.35s ease 0s;
      }

      .site-navigation .nav-parts {
        margin-top: 15px;
        transition: all 0.35s ease 0s;
      }

      .site-navigation:after {
        top: 80px;
        transition: all 0.35s ease 0s;
      }

      .logo {
        margin: 0.5em 0 !important;

        a {
          img {
            transform: scale(0.8);
          }

          svg {
            width: 150px;
            height: 96px;
          }

        }

      }

    }
  }


  .breadcrumbs {
    transition: all 0.35s ease 0s;

    @include breakpoint(medium up) {
      position: absolute;
      left: 220px;
      top: 115px;
    }

    @include breakpoint(small down) {
      background: $black;
      margin: 73px 0 0;
      padding: 20px 10px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        color: $white;
        text-transform: none;

        &:not(:last-child)::after {
          content: '>';
          margin: 0 0.50rem;
        }

        a {
          text-transform: none;
        }

      }

    }

  }
}
