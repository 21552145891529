/***

  CUSTOM PAGINATION

***/
.custom-pagination {
  display: block;
  margin: 25px 0 25px;
  padding: 0;
  position: relative;

  @include breakpoint(medium up) {
    margin: 25px 0 0;
    text-align: right;
  }

  @include breakpoint(large up) {
    margin: 50px 0 0;
  }

  span,
  a {
    display: inline-block;
    padding: 2px 10px;
    font-size: inherit;

    @include breakpoint(large up) {
      font-size: 18px;
    }

    &.page-numbers {
      margin-right: 5px;
      padding: 0;
    }

    &.dots {
      padding: 0;
      color: gainsboro;
    }

    &.current {
      background: none;
    }

    &.prev, &.next {
      position: relative;
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      padding: 0 0 0 25px;
      transition: all 0.3s ease 0s;
      right: 25px;
      top: 0;
      text-transform: uppercase;

      &:after {
        content: '\f104' !important;
        font-family: FontAwesome;
        font-size: 30px;
        line-height: 80%;
        opacity: 1;
        padding-right: 2px;
        position: absolute;
        transition: all 0.3s ease 0s;
        left: 0;
        top: 0;
        text-indent: 0;
      }
    }

    &.next {
      padding: 0 25px 0 0;
      right: 0;

      &:after {
        content: '\f105' !important;
        left: initial;
        right: 0;
      }

      @include breakpoint(small down) {
        float: right;
      }

    }

    &.prev {
      @include breakpoint(small down) {
        left: 5px;
        right: initial;
      }
    }

  }

  a {
    text-decoration: none;
    font-size: inherit;

    @include breakpoint(large up) {
      font-size: 18px;
    }

    &:hover {
      background: none;
    }
  }

}
