.sidebar {
  &.mobile-top {

    @include breakpoint(large down) {
      margin-top: 0;
      margin-bottom: 1em;

      .title-bar-title {
        padding-left: 3em;

        h5 {
          margin-bottom: 0;
        }
      }

      #subnav-menu-icon {
        width: 30px;
        height: 24px;
        margin: 1px 5px 5px;
        outline: none;
        position: absolute;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        left: 15px;

        &::after {
          content: none;
          display: none;
        }

        span {
          display: block;
          position: absolute;
          height: 5px;
          width: 100%;
          border-radius: 9px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .25s ease-in-out;
          -moz-transition: .25s ease-in-out;
          -o-transition: .25s ease-in-out;
          transition: .25s ease-in-out;

          &:nth-child(1) {
            top: 0;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
          }

          &:nth-child(2) {
            top: 9px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
          }

          &:nth-child(3) {
            top: 18px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
          }

        }

        &.open {
          outline: none;

          span {

            &:nth-child(1) {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
              top: -3px;
              left: 8px;
            }

            &:nth-child(2) {
              width: 0%;
              opacity: 0;
            }

            &:nth-child(3) {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
              top: 19px;
              left: 8px;
            }

          }

        }
      }

      ul.menu {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        padding: 1em 1.5em;
      }

    }

  }

  ul.menu {
    background-color: $primary-color;
    padding: 2em 30px;
    display: block;
    letter-spacing: -0.3px;
    margin: 0 0 1em;

    @include breakpoint(large up) {
      margin: 0 0 35px;
    }

    h4 {
      color: $white;
    }

    > li {
      display: block;
      width: 100%;
      padding: 0;

      &:before {
        display: none;
      }

      > a {
        color: $black;
        font-family: 'Oswald', sans-serif;
        font-weight: 700;
        font-size: 15px;
        line-height: 120%;
        display: block;
        padding: 10px 0;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;

        span {
          display: block;
        }

        &:hover, &:focus {
          color: $white;
        }

        h3 {
          display: none;
        }
      }

      ul {
        display: none;
      }

    }

  }

  .sidebar-block {
    background: $white;
    margin-top: 1em;

    @include breakpoint(large up) {
      margin-top: 35px;
    }

    &.is-anchored {
      margin-top: 1em !important;

      @include breakpoint(large up) {
        margin-top: 35px !important;
      }
    }

    h5 {
      color: $secondary-color;
    }

    .sidebar-content {
      padding: 2em 30px;

      @include breakpoint(small down) {
        padding: 1.5em 15px;
      }
    }

    &:first-of-type {
      margin-top: 0;
      @include breakpoint(medium down) {
        margin-top: 0;
      }
    }

    .image {
      height: 195px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      span {
        display: inline-block;
        width: 75px;
      }

      li {
        color: $black;
      }

    }

    a, button {
      color: $secondary-color;

      &:hover {
        color: $black;
      }

      &.button {
        color: $white;

        &.arrow {
          display: block !important;
          text-align: left;
          width: 100%;
        }

      }

    }

    p {
      color: $black;
      margin: 0;

      + * {
        margin: 1.5em 0 0;
      }

    }


    &.highlighted {
      background: $primary-color;

      h4 {
        color: $white;
      }

      ul li, p {
        color: $white;
      }

      a, button {
        color: $white;

        &.button {
          background: $black;
          border-color: $black;

          &:hover {
            background-color: #e61f12;
            border-color: #d5281e;
          }

        }

      }

    }

    &.wrapup {
      p {
        a {
          color: #e61f12;

          &:hover, &:focus {
            color: #ba844a;
          }

        }
      }

      form {
        padding-bottom: 0;
        table {
          tbody {
            tr {
              &.free-product {
                background: #ba844a;
                td {
                  color: $white;

                  a {
                    color: $white;
                  }

                  &.product-free {
                    text-align: right;
                  }

                }
              }

              td {
                &.product-quantity {
                  padding: 10px 15px 10px 30px !important;
                }
                .quantity {
                  text-align: right;
                }
              }

              &.total-roundup {
                background: #00a651;
                color: $white;
              }

            }
          }
        }
      }

    }

  }

}

