@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot');
  src: url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.woff') format('woff'),
  url('../fonts/icomoon.ttf') format('truetype'),
  url('../fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Common styles of menus */

.dl-menuwrapper {
  width: 100%;
  float: left;
  //position: relative;
  //-webkit-perspective: 1000px;
  //perspective: 1000px;
  //-webkit-perspective-origin: 50% 200%;
  //perspective-origin: 50% 200%;

  &:first-child {
    margin-right: 100px;
  }

  button {
    background: transparent;
    border: none;
    width: 80px;
    height: 45px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    outline: none;

    &:hover, &.dl-active {
      background: transparent;
    }

  }

  ul {
    background: #aaa;
    height: 100vh;
    padding: 50px 0 0 25px;
    list-style: none;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;

    @include breakpoint(medium up) {
      padding: 65px 0 0 50px;
    }

    @include breakpoint(large up) {
      padding: 100px 0 0 75px;
    }

    @include breakpoint(xlarge up) {
      padding: 100px 0 0 150px;
    }

    li {
      position: relative;
      a {
        display: block;
        font-family: 'Oswald', sans-serif;
        font-weight: 700;
        position: relative;
        padding: 10px 20px 0 0;
        font-size: 16px;
        line-height: 150%;
        color: #fff;
        outline: none;
        text-transform: uppercase;
        transition: all 0.35s ease 0s;
        text-decoration: none;

        @include breakpoint(medium up) {
          font-size: 30px;
        }

        @include breakpoint(xlarge up) {
          font-size: 40px;
        }

        &:hover {
          color: $primary-color;
        }

      }

      &.active {
        a {
          color: $primary-color;
        }
      }

    }

  }

}

.dl-menuwrapper li.dl-back > a {
  padding-left: 30px;
  background: rgba(0, 0, 0, 0.1);
}

.dl-menuwrapper .dl-menu {
  left: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  right: 0;
  top: 0;
  z-index: 8;
}

.dl-menuwrapper .dl-menu.dl-menu-toggle {
  transition: all 0.3s ease;
}

.dl-menuwrapper .dl-menu.dl-menuopen {
  opacity: 1;
  height: 100vh;
  overflow: auto;
  padding-bottom: 100px;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

/* Hide the inner submenus */
.dl-menuwrapper li .dl-submenu {
  display: none;
}

/*
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link.
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
  display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
  display: block;
}

/* Dynamically added submenu outside of the menu context */
.dl-menuwrapper > .dl-submenu {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
}

/* Animation classes for moving out and in */
.dl-menu.dl-animate-out-2 {
  -webkit-animation: MenuAnimOut2 0.3s ease-in-out;
  animation: MenuAnimOut2 0.3s ease-in-out;
}

@-webkit-keyframes MenuAnimOut2 {
  0% {
  }
  100% {
    -webkit-transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes MenuAnimOut2 {
  0% {
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }
}

.dl-menu.dl-animate-in-2 {
  -webkit-animation: MenuAnimIn2 0.3s ease-in-out;
  animation: MenuAnimIn2 0.3s ease-in-out;
}

@-webkit-keyframes MenuAnimIn2 {
  0% {
    -webkit-transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes MenuAnimIn2 {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-2 {
  -webkit-animation: SubMenuAnimIn2 0.3s ease-in-out;
  animation: SubMenuAnimIn2 0.3s ease-in-out;
}

@-webkit-keyframes SubMenuAnimIn2 {
  0% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn2 {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-2 {
  -webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
  animation: SubMenuAnimOut2 0.3s ease-in-out;
}

@-webkit-keyframes SubMenuAnimOut2 {
  0% {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut2 {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
}

/* No JS Fallback */
.no-js .dl-menuwrapper .dl-menu {
  position: relative;
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
.no-js {
  .content-wrap {
    display: none;
    visibility: hidden;
  }
}
.no-js {
  .logo {
    display: none;
    visibility: hidden;
  }
}

.no-js .dl-menuwrapper li .dl-submenu {
  display: block;
}

.no-js .dl-menuwrapper li.dl-back {
  display: none;
}

.no-js .dl-menuwrapper li > a:not(:only-child) {
  background: rgba(0, 0, 0, 0.1);
}

.dl-menuwrapper button {
  background: transparent;
  color: $white;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  font-size: 15px;
  float: right;
  height: 17px;
  margin: 0;
  outline: none;
  //position: absolute;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  //right: 1.25rem;
  //top: 0;
  width: 80px;
  z-index: 99;
  text-transform: uppercase;

  &:after {
    content: "Menu";
    position: absolute;
    left: 30px;
    top: 0;
  }

  span {
    background: $white;
    display: block;
    position: absolute;
    height: 3px;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    width: 20px;

    &:nth-child(1) {
      top: 0;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 7px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 14px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

  }

  &.dl-active {
    outline: none;

    &:after {
      content: "Sluiten";
    }

    .logo-scroll {
      display: block;
    }

    span {

      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -1px;
        left: 8px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 14px;
        left: 8px;
      }

    }

  }

}

.dl-menuwrapper button:hover,
.dl-menuwrapper button.dl-active,
.dl-menuwrapper ul {
  background: transparent;
}

.dl-menuwrapper ul {
  background: url('../../assets/img/bg_menu.png') no-repeat top center $black;
  background-size: contain;

  @include breakpoint(medium up) {
    background-size: cover;
  }

}

html {
  &.overlay-menu-blocking {
    body {
      overflow: hidden;
    }
    .site-navigation {
      position: initial;
    }
  }
}