.overview-blocks-carousel {
  background: transparent;
  height: auto;
  margin: 0;

  .item {
    margin: 0 10px;
    position: relative;

    @include breakpoint(large up) {
      margin: 0 20px;
    }
  }
}
.orders-carousel {
  background: $white;
  height: auto;
  margin: 0;
  padding: 0 20px;

  .item {
    padding: 0 10px;
    position: relative;

    .label {
      background: $primary-color;
      color: $white;
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      font-size: 13px;
      left: 10px;
      padding: 7px 10px;
      position: absolute;
      top: 0;
      text-transform: uppercase;
      z-index: 9;
    }

  }

  .image {
    margin-bottom: 18px;
    height: 200px;

    @include breakpoint(large up) {
      height: 245px;
    }

  }

  .text-wrap {
    h6 {
      color: $primary-color;
      margin-bottom: 0.2em;
    }

    .price {
      margin-bottom: 18px;

      span {
        color: #a1a1a1;
      }
    }

    .button {
      border-bottom: 3px solid #d73930;
      color: $white;
      width: auto;
      text-decoration: none;
    }

  }

}


.carousel-block {
  margin: 1em 0 1em;

  @include breakpoint(large up) {
    margin-bottom: 80px;
  }

  .carousel-large {
    background: none;
    height: auto;
    margin-bottom: 1em;
    padding: 0 20px;

    @include breakpoint(large up) {
      margin-bottom: 0;
      padding: 0 40px;
    }

    .section-image {
      margin: 0;
    }

    .image {
      height: 200px;

      @include breakpoint(medium up) {
        height: 250px;
      }

      @include breakpoint(large up) {
        height: 500px;
      }

    }

    .slick-arrow {
      top: 50%;


      &:before {
        color: $white;
      }

    }

  }

}

.main-slider {
  background: none;
  height: auto;
  margin-top: 0;
  margin-bottom: 1em;
  padding: 0 0 10px;

  @include breakpoint(large up) {
    padding: 0;
  }

  @include breakpoint(xlarge up) {
    margin-bottom: 0;
    padding: 0 0 10px;
  }

  .image {
    height: 185px;

    @include breakpoint(medium up) {
      height: 250px;
    }

    @include breakpoint(xlarge up) {
      height: 315px;
    }

  }
}


.review-carousel {
  .review {

    @include breakpoint(small down) {
      padding: 1em 2em !important;
    }
  }
}

.main-slider-nav {
  background: none;
  height: auto;
  margin-top: 0;
  margin-bottom: 1em;
  padding: 0 20px;

  @include breakpoint(xlarge up) {
    margin-bottom: 0;
  }

  .image {
    height: 65px;
    padding: 0 5px;

    @include breakpoint(large up) {
      height: 65px;
    }

  }

  .slick-arrow {
    top: 50%;

    &:before {
      color: $secondary-color !important;
    }

  }

}

.overview-blocks-carousel {


  .slick-arrow {
    background: #ef4035 !important;
    border-radius: 0 !important;
    height: 40px !important;
    top: 48% !important;
    width: 40px !important;

    &:before {
      color: $white !important;
      font-size: 36px !important;
    }

    &:hover {
      background: #e61f12 !important;
    }

  }

  .slick-prev, .slick-next {
    left: -10px !important;

    @include breakpoint(large up) {
      left: -20px !important;
    }

    @include breakpoint(xxlarge up) {
      left: -60px !important;
    }
  }

  .slick-next {
    left: inherit !important;
    right: -10px !important;

    @include breakpoint(large up) {
      right: -20px !important;
    }

    @include breakpoint(xxlarge up) {
      right: -60px !important;
    }
  }

}

.slick-slider {

  .slick-arrow {
    background: transparent;
    border: none;
    border-radius: 50%;
    bottom: inherit;
    height: 20px;
    width: 20px;
    top: 32%;
    transition: all 0.35s ease 0s;
    z-index: 8;

    @include breakpoint(small down) {
      top: 28%;
    }

    &.slick-disabled {
      display: none !important;
    }

    &:hover {
      background: transparent;
      border: none;

      &.slick-prev, &.slick-next {

        &:before {
          color: $primary-color;
          content: '\f104' !important;
        }

      }

      &.slick-next {

        &:before {
          content: '\f105' !important;
        }

      }

    }

  }

  .slick-prev, .slick-next {
    left: 0.5em;
    transform: none;

    &:before {
      color: $black;
      content: '\f104' !important;
      display: block;
      font-family: FontAwesome;
      font-size: 22px;
      font-weight: bold;
      opacity: 1;
      padding-right: 4px;
      transition: all 0.3s ease 0s;
    }

  }

  .slick-next {
    left: inherit;
    right: 0.5em;

    &:before {
      content: '\f105' !important;
      font-family: FontAwesome;
      padding-left: 4px;
      padding-right: 0;
    }

  }

  .slick-dots {
    bottom: 35px;
    padding-left: 25px;
    text-align: left;

    @include breakpoint(small down) {
      bottom: 15px;
      padding-left: 5px;
    }

    li {
      height: 16px;
      width: 16px;

      button {
        background: transparent;
        border: 2px solid $white;
        border-radius: 50%;
        height: 16px;
        width: 16px;

        &:hover {
          background: $primary-color;
          border: 2px solid $primary-color;
        }

        &:before {
          content: none;
        }

      }

      &.slick-active {

        button {
          background: $primary-color;
          border: 2px solid $primary-color;
        }

      }

    }

  }

}