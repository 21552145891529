.mobile-button {
  a {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 99;
  }
}
.overview-block {

  &.with-background {
    background-size: contain;
    padding-top: 1em;
    padding-bottom: 1em;
    position: relative;
    z-index: 1;

    @include breakpoint(large up) {
      background-size: cover;
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @include breakpoint(xlarge up) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    &:before {
      background: rgba(0, 0, 0, 0.9);
      content: '';
      left: 0;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      z-index: -1;
    }

    .title {
      h4 {
        color: $white;

        @include breakpoint(large up) {
          margin-bottom: 1.5em !important;
        }

        a{
          color: #ef4035 !important;
        }

      }

      &.extra-text {
        margin: 1.5em 0 0;

        h4 {
          margin-bottom: 0 !important;
        }

      }

    }

    .item {
      .content-wrap {
        .title {
          color: #ba844a !important;
        }
      }

      &:hover, &:focus {
        .content-wrap {
          width: 100% !important;
        }
      }

    }

    .accordion {
      .accordion-item {
        .title-wrap {
          .title {
            color: #ba844a !important;
          }
        }
      }
    }

  }

  .item {

    a {
      display: block;
      position: relative;

      &:hover {

        .image {
          img {
            filter: initial;
            transition: all 0.3s ease 0s;
            transform: scale(1.1);
          }
        }

        .content-wrap {
          background: url('../../assets/img/background_item-content-hover.png') no-repeat bottom center;
          background-size: cover;
          transition: all 0.3s ease 0s;

          &:after {
            bottom: 30px;
            background-image: url('../../assets/img/pijltje_omlaag.svg');
            background-size: 20px 15px;
            background-position: center center;
            content: "";
            height: 15px;
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
            width: 20px;
            transition: all 0.3s ease 0s;

            @include breakpoint(medium down) {
              display: none;
            }
          }

        }

        .title, .text {
          color: $white;
          transition: all 0.3s ease 0s;
        }

      }

    }

    span {
      display: block;
    }

    .image {
      height: 75px;
      width: 30%;

      @include breakpoint(medium up) {
        height: 100px;
        width: initial;
      }

      @include breakpoint(large up) {
        height: 400px;
      }

      @include breakpoint(xlarge up) {
        height: 550px;
      }

      @include breakpoint(xxlarge up) {
        height: 600px;
      }

      img {
        filter: grayscale(100%);
        transition: all 0.3s ease 0s;
      }

    }

    .content-wrap {
      background: url('../../assets/img/background_item-content.png') no-repeat bottom center;
      background-size: cover;
      bottom: 0;
      height: 155px;
      padding: 0 1em;
      position: absolute;
      width: 100%;
      transition: all 0.3s ease 0s;
      text-align: center;

      @include breakpoint(xlarge up) {
        height: 135px;
        padding: 0 1.5em;
      }

      @include breakpoint(xxlarge up) {
        height: 155px;
        padding: 0 2.5em;
      }

      @include breakpoint(medium down) {
        height: 100%;
        width: 60%;
        right: 0;
      }

      @include breakpoint(small down) {
        width: 70%;
      }

      .icon {
        left: 0;
        position: absolute;
        right: 0;
        z-index: 99;
        top: -30px;
        width: 66px;
        margin: 0 auto;

        @include breakpoint(medium down) {
          top: 20px;
          right: initial;
          left: -30px;
        }

        @include breakpoint(small down) {
          top: 10px;
        }

        svg {
          height: 60px;
          width: 60px;
        }

        &.check {
          margin: initial;
          width: 17px;
        }

      }

    }

    .title, .text, .price {
      color: $black;
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      transition: all 0.3s ease 0s;
    }

    .title {
      font-size: 18px;
      font-style: normal;
      line-height: 120%;
      margin-top: 0;
      margin-bottom: 0.2em;
      padding-left: 0;
      text-align: left;

      @include breakpoint(medium up) {
        font-size: 20px;
        margin-top: 25px;
        padding-left: 35px;
      }

      @include breakpoint(large up) {
        padding-left: 0;
        text-align: center;
      }

      @include breakpoint(xlarge up) {
        font-size: 25px;
      }

      @include breakpoint(xxlarge up) {
        font-size: 30px;
      }

      @include breakpoint(small down) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

    }

    .icon {
      + * {
        margin-top: 0;
        @include breakpoint(medium up) {
          margin-top: 38px;
        }
        @include breakpoint(large up) {
          margin-top: 40px;
        }
        @include breakpoint(small down) {
          margin-left: 20px;
        }
      }
    }

    .text {
      font-size: 15px;
      line-height: 120%;
    }

    &.employee {

      @include breakpoint(medium down) {
        margin-bottom: 0;
      }

      a {

        .image {
          height: 250px;
          width: initial;

          @include breakpoint(medium up) {
            height: 495px;
          }

          @include breakpoint(xlarge up) {
            height: 450px;
          }

          @include breakpoint(xxlarge up) {
            height: 495px;
          }

          img {
            filter: initial;
          }

        }

        .content-wrap {
          height: 65px;
          width: 100%;
          right: initial;

          @include breakpoint(xlarge up) {
            height: 70px;
          }

          @include breakpoint(xxlarge up) {
            height: 80px;
          }

          @include breakpoint(medium down) {
            padding: 0 0.5em;
          }

          .title {
            margin-top: 20px;

            @include breakpoint(medium down) {
              padding-left: 0;
              text-align: center;
            }

            @include breakpoint(small down) {
              margin-top: 0;
            }
          }

        }

        &:hover {

          .image {
            img {
              transform: initial;
            }
          }

          .content-wrap {
            height: 85px;
            width: 100%;
            right: initial;

            @include breakpoint(large up) {
              height: 105px;
            }

            @include breakpoint(xlarge up) {
              height: 110px;
            }

            @include breakpoint(xxlarge up) {
              height: 135px;
            }

            &:after {
              @include breakpoint(medium down) {
                bottom: 15px;
                display: block;
              }
            }
          }
        }

      }
    }

  }

  &.full {
    .title {
      margin-bottom: 0.5em;

      @include breakpoint(xxlarge up) {
        margin-bottom: 1em;
      }

      h2 {
        margin-bottom: 0.3em;
      }

      h4 {
        font-weight: 400;
      }

      a {
        text-decoration: none;

        &[href^="tel"] {
          color: $primary-color !important;
        }

      }

    }

    .accordion {
      background: transparent;
      display: block;
      width: 100%;

      .accordion-item {
        margin: 0 10px 10px;

        &.is-active > .accordion-title::before {
          content: '\f077';
          font-family: FontAwesome;
          transition: all 0.3s ease 0s;
        }

        .accordion-title {
          background: $white;
          border: none;
          padding: 0;

          &::before {
            content: '\f078';
            font-family: FontAwesome;
            transition: all 0.3s ease 0s;
          }

        }

        .image {
          float: left;
          height: 85px;
          width: 30%;
        }

        .title-wrap {
          float: left;
          padding-left: 15px;
          width: 70%;
          left: 30%;
          text-align: left;
          line-height: 150%;
          padding-right: 25px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          .title {
            margin-top: 0;
            color: $primary-color;
            position: relative;
            top: initial;
            transform: initial;
            text-align: left;
          }

        }

      }

      .accordion-content {
        text-align: left;

        .button {
          margin-top: 15px;
        }

        .content-wrap {
          padding: 0;
          text-align: left;
        }

        .button {
          margin-left: 0;
        }

      }

    }

    .item {
      background: $white;
      position: relative;

      @include breakpoint(medium up) {
        height: 100%;
      }

      .image {
        height: 150px;

        @include breakpoint(xxlarge up) {
          height: 200px;
        }

        img {
          filter: grayscale(0);
        }

      }

      .content-wrap {
        background: $white;
        height: auto;
        padding: 15px 15px 0;
        position: relative;
        width: 100%;
        text-align: center;

        @include breakpoint(medium up) {
          padding: 15px 25px 70px;
        }

        @include breakpoint(large up) {
          padding: 15px 25px 80px;
        }

        .title {
          color: $primary-color;
          margin-top: 0;
          text-align: center;
        }

        .price {
          margin-bottom: 10px;
          @include breakpoint(medium up) {
            margin-bottom: 10px;
          }
        }

        ul {
          &.list {
            list-style: none;
            margin-left: 0;
            padding-left: 0;
            text-align: left;

            li {
              line-height: 150%;
              position: relative;
              padding-left: 30px;
              @include breakpoint(medium up) {
                line-height: 200%;
              }

              span {
                margin-top: 0;
              }

              .check {
                left: 0;
                top: 0;

                svg {
                  height: 15px;
                  width: 16px;
                  path {
                    fill: $secondary-color
                  }
                }
              }
            }
          }
        }

      }

      .button {
        margin-bottom: 15px;
        margin-left: 15px;

        @include breakpoint(medium up) {
          bottom: 20px;
          left: 25px;
          margin: 0;
          float: initial;
          position: absolute;
          right: 25px;
        }

        @include breakpoint(large up) {
          bottom: 30px;
          left: 40px;
          right: 40px;
        }

        &.arrow {
          padding: 18px 50px 15px 25px;
        }
      }

    }

    &.reference {

      .item {
        @include breakpoint(small down) {
          text-align: left;
        }
      }

      a {
        &:hover {
          .content-wrap {
            background: none;
            height: initial;
          }
          .button {
            background: #e61f12;
            border-bottom: 3px solid #d5281e;
          }
        }
      }

      .image {
        height: 245px;

        @include breakpoint(small down) {
          float: left;
          height: 125px;
          width: 30%;
        }
      }

      .title {
        color: $primary-color;
        font-size: 18px;
        font-weight: 700;
        font-family: 'Oswald', sans-serif;
        line-height: 120%;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      .content-wrap {
        background: $white;
        height: auto;
        float: left;
        min-height: 63px;
        padding: 15px 15px 10px;
        position: relative;
        width: 70%;
        text-align: left;

        @include breakpoint(medium up) {
          float: initial;
          min-height: auto;
          text-align: center;
          width: 100%;
          padding: 25px 40px 85px;
        }

        @include breakpoint(large up) {
          padding: 25px 30px 85px;
        }

        @include breakpoint(xlarge up) {
          padding: 25px 60px 85px;
        }

        @include breakpoint(xxxlarge up) {
          padding: 25px 80px 85px;
        }
      }

      .button {
        margin-bottom: 12px;

        @include breakpoint(medium up) {
          bottom: 25px;
          margin-bottom: 0;
          left: 40px;
          right: 40px;
        }
        @include breakpoint(large up) {
          left: 30px;
          right: 30px;
        }
        @include breakpoint(xlarge up) {
          left: 60px;
          right: 60px;
        }
        @include breakpoint(xxxlarge up) {
          left: 80px;
          right: 80px;
        }
      }

    }

  }

}
