.arrangement-block {

  .about {
    background: $white;
    color: $black;
    padding: 15px 0;

    @include breakpoint(medium up) {
      padding: 20px;
    }
    @include breakpoint(large up) {
      padding: 40px;
    }

    .info {
      border-bottom: 1px solid #181919;
      margin-bottom: 25px;
      padding: 0 0 25px;

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding: 0;
      }

      ul {
        list-style: none;
        line-height: 180%;
        margin-left: 5px;

        @include breakpoint(medium up) {
          display: block;
          float: left;
          margin-left: 0;
        }

        li {
          position: relative;
          padding-left: 17px;

          &::before {
            content: "";
            background-image: url('../../assets/img/bullet.png');
            background-repeat: no-repeat;
            display: inline-block;
            left: 0;
            height: 6px;
            margin-top: 11px;
            position: absolute;
            width: 6px;
          }
        }
      }

      h6 {
        font-weight: 400;
        margin-bottom: 0.5em;
      }

      a {
        display: block;
        position: relative;

        &.download {
          padding-left: 30px;

          .icon {
            left: 0;
            position: absolute;
            top: 2px;

            svg {
              height: 21px;
              width: 21px;
              path {
                transition: all 0.3s ease 0s;
              }
            }
          }

          &:hover {
            padding-left: 35px;

            .icon {
              svg {
                path {
                  fill: #e91f12;
                  transition: all 0.3s ease 0s;
                }
              }
            }
          }

        }

        &.moreproducts {
          color: #00a651;
          margin: 0 0 5px;
          padding: 0 0 0 30px;

          .icon {
            svg {
              height: 21px;
              width: 21px;
              path {
                fill: #00a651;
                transition: all 0.3s ease 0s;
              }
            }
          }

          &:hover {
            padding-left: 35px;

            .icon {
              svg {
                path {
                  fill: #00a651;
                  transition: all 0.3s ease 0s;
                }
              }
            }
          }

        }

      }

    }

  }

  .order {
    background: #181919;
    color: $white;
    padding: 15px 10px;

    @include breakpoint(medium up) {
      padding: 20px;
    }
    @include breakpoint(large up) {
      padding: 40px;
    }

    .details {
      .price {
        color: $primary-color;
        font-weight: 700;
        font-family: 'Oswald', sans-serif;
        font-size: 20px;
        margin-bottom: 0.3em;
        text-transform: uppercase;

        @include breakpoint(large up) {
          font-size: 40px;
        }

      }

      a, button {
        display: block;
        font-size: 15px;

        &.button {
          margin: 0 0 15px;
          font-size: 16px;

          @include breakpoint(large up) {
            margin: 20px 0 15px;
          }

          @include breakpoint(medium down) {
            bottom: 0;
            left: 0;
            margin-bottom: 0;
            position: fixed;
            right: 0;
            width: 100%;
            z-index: 999;
          }
        }

      }


    }


  }

}