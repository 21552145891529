.banner {
  margin-top: 15px;

  @include breakpoint(medium up) {
    margin-top: 20px;
  }

  @include breakpoint(large up) {
    margin-top: 40px;
  }

  .cta {
    a {
      background: #bb854a;
      display: block;
      position: relative;

      &:after {
        background: url('../../assets/img/icon_more.png') no-repeat;
        content: "";
        height: 24px;
        margin: 0 auto;
        top: 55%;
        transform: translateY(-50%);
        position: absolute;
        right: 2.5em;
        width: 36px;
        z-index: 99;
        transition: all 0.35s ease 0s;
      }

      &:hover {
        background: #9b672e;
        &:after {
          right: 2em;
        }
      }

    }

    .image-wrap {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;

      img {

        @include breakpoint(medium up) {
          max-height: 150px;
          max-width: 200px;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: 50%;
          transform: translateY(-50%);
        }

        @include breakpoint(large up) {
          max-height: 150px;
          max-width: 250px;
        }

        @include breakpoint(xlarge up) {
          max-height: 150px;
          max-width: 325px;
        }

      }

    }

    .title {
      color: $white;
      font-size: 20px;
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      font-style: normal;
      padding: 15px 15px;
      text-transform: uppercase;
      display: block;

      @include breakpoint(medium up) {
        font-size: 24px;
        padding: 20px 15px;
      }

      @include breakpoint(large up) {
        font-size: 30px;
        padding: 30px 0;
      }

      @include breakpoint(xlarge up) {
        font-size: 32px;
      }

    }

  }
}