.content-block {

  h1 {
    @include breakpoint(small down) {
      float: left;
    }
  }

  .text {
    position: relative;

    @include breakpoint(large up) {
      &.no-media {
        display: inline-block;
        column-count: 2;
        column-gap: 70px;
      }
    }

    &.with-stamp {
      padding-bottom: 10px;

      @include breakpoint(medium up) {
        padding-bottom: 20px;
      }
    }


    p {
      line-height: 180%;

      + h2, + h3, + h4, + h5, + h6 {
        margin-top: 1.5em;
        margin-bottom: 0.3em;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

    }

    blockquote {
      border-left: none;
      p {
        color: $secondary-color;
        font-family: 'Oswald', sans-serif;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 0.1em !important;
        text-transform: uppercase;

        @include breakpoint(medium up) {
          font-size: 25px;
        }

        @include breakpoint(large up) {
          font-size: 30px;
        }

      }

      span {
        color: $secondary-color;
        font-size: 15px;
      }

    }

    .stamp {
      bottom: 0;
      position: absolute;
      right: 0;
      z-index: -1;

      img {
        max-width: 100px;

        @include breakpoint(medium up) {
          max-width: 200px;
        }

        @include breakpoint(xlarge up) {
          max-width: 355px;
        }

      }

    }

  }

  .go-back {
    color: $primary-color;
    display: block;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    font-size: 18px;
    margin-top: 1em;
    padding-left: 20px;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;

    &:before {
      color: $primary-color;
      content: '\f104' !important;
      display: block;
      font-family: FontAwesome;
      font-size: 22px;
      font-weight: bold;
      opacity: 1;
      left: 0;
      position: absolute;
      top: -3px;
      transition: all 0.3s ease 0s;
    }

    &:hover {
      padding-left: 25px;
    }

    @include breakpoint(large up) {
      float: right;
    }
  }

}