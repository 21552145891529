
.portfolio-map-filter {
  background: rgba(0, 0, 0, 0.5);
  margin: 0 0 40px;
  padding: 14px 0 5px;
  position: relative;
  overflow: hidden;
  width: 100%;

  @include breakpoint(medium up) {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  ul {
    color: #ffffff;
    display: inline-block;
    list-style: none;
    margin: 0;
  }

  li {
    a {
      color: #ffffff;
      text-transform: uppercase;

      &:hover, &:focus {
        background: transparent;
        color: #000000;
      }

    }
  }

  .portfolio-map-filter-options {
    background: transparent;
    @include breakpoint(medium up) {
      overflow: hidden;
    }

    .sf-field-category {
      padding: 0;

      &:before {
        content: none !important;
        display: none !important;
      }

      li {
        font-size: 14px;
        padding: 0;

        &:before {
          content: none !important;
          display: none !important;
        }

        label {
          padding-left: 0 !important;
          color: #ffffff;
          font-family: 'Oswald', sans-serif;
          font-weight: 400;
          font-size: 18px !important;
        }

        @include breakpoint(medium up) {
          float: left;
        }
      }

    }
  }

  .portfolio-map-filter-options,
  .portfolio-map-filter-title {
    color: #ef4035;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    @include breakpoint(medium up) {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .portfolio-map-filter-title {
    //background: #ef4035 !important;
    left: 0;
    top: 0;
    padding: 0.9em 1.5em 1em 3em !important;
    position: absolute;
    @include breakpoint(small down) {
      position: relative;
      margin-bottom: 0;
      padding: 0.9em 1.5em 0.9em 3em !important;
    }

    &:before {
      background: url('../../assets/img/filter_pre.png') no-repeat left center;
      content: '' !important;
      height: 16px;
      left: 0.8em;
      position: absolute;
      width: 26px;
      top: 20px;

      @include breakpoint(small down) {
        top: 24px;
      }
    }

  }

  .accordion-item {
    margin: 0 !important;
  }

  + * {
    margin-top: 27px;
  }
  .sf-input-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }

    // Box.
    & + label:before {
      border: 1px solid #d3f2f9;
      box-shadow: inset 1px 1px 1px 0px rgba(217, 217, 217, 1);
      content: '';
      margin: 4px 1em 0 0;
      display: inline-block;
      vertical-align: text-top;
      width: 17px;
      height: 17px;
      background: white;
    }

    // Box hover
    &:hover + label {
      color: #ef4035 !important;
    }
    &:hover + label:before {
      background: #ef4035 !important;
      border: none;
      box-shadow: inset 1px 1px 1px 0px rgba(143, 38, 32, 1) !important;
      content: '';
      left: 0;
      top: 7px;
      width: 17px;
      height: 17px;
    }
    // Checkmark. Could be replaced with an image
    &:hover + label:after {
      background: url('../../assets/img/icon_check.png') no-repeat left center;
      content: '';
      position: absolute;
      left: 3px;
      top: 11px;
      width: 12px;
      height: 9px;
    }

    // Box focus
    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    &:checked + label {
      color: #ef4035 !important;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:before {
      background: #ef4035 !important;
      box-shadow: inset 1px 1px 1px 0px rgba(143, 38, 32, 1);
      border: none;
      content: '';
      left: 0;
      top: 7px;
      width: 17px;
      height: 17px;
    }
    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      background: url('../../assets/img/icon_check.png') no-repeat left center;
      content: '';
      position: absolute;
      left: 3px;
      top: 11px;
      width: 12px;
      height: 9px;
    }
  }

}