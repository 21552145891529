form {
  select, [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='file'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    height: 41px;
    font-size: 15px;
    margin-bottom: 0;
    padding: 0 10px;
    border: 1px solid #ebebeb;
    background: #fff;
    outline: none !important;

    &::placeholder {
      color: #898989;
    }
  }

  select {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='12' height='10' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2882, 82, 82%29'></polygon></svg>");
    background-repeat: no-repeat;
    background-position: right 20px center;
    color: #898989 !important;

    option {
      color: #898989 !important;
    }
  }

  textarea {
    resize: none;
    margin-bottom: 1rem;
    padding: 10px 10px;

    @include breakpoint(large up) {
      margin-bottom: 0;
    }

    &[name="your-message"] {
      min-height: 128px;
      @include breakpoint(large up) {
        min-height: 245px;
      }
    }
  }

  label {
    position: relative;
    span.text {
      //background: #ffffff;
      padding: 0 0 0 15px;
      font-size: 12px;
      //position: absolute;
      //bottom: 23px;
      //z-index: 99;
      //width: 170px;
      //left: 140px;
    }
  }

  input[type="file"] {
    color: #555555 !important;
    height: 42px;
    padding: 10px;
  }

  .wpcf7-list-item {
    position: relative;

    &.first {
      margin-left: 0;
    }

    .wpcf7-list-item-label {
      margin-left: 1.5em;
    }

  }

  .wpcf7-list-item span.checkbutton {
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 4px;
    width: 15px;
    height: 15px;
    box-shadow: inset 2px 2px 2px 0px #cccccc;
    background: #ffffff;
  }

  input[type=radio] {
    cursor: pointer;
    margin: 4px 0 0 0;
    height: 15px;
    position: absolute;
    width: 100%;
    z-index: 99;
  }

  input[type=checkbox]:checked + span:after {
    content: "\00d7";
    font-weight: bold;
    position: absolute;
    top: -7px;
    left: 1px;
    font-size: 20px;
    color: #6ab5a8;
  }

  input[type=radio],
  input[type=checkbox] {
    /*hide the radio button*/
    filter: alpha(opacity=0);
    opacity: 0;
  }

  input[type="button"],
  input[type="submit"] {
    height: auto;
  }

  .wpcf7-not-valid {
    border: 1px solid #a94442;
    background-color: #fdeeec;
    &::placeholder {
      color: #a94442;
    }
  }
  input[type=file] + label.is-invalid-label:not(:focus) {
    border: 1px solid #a94442;
  }

  .is-invalid-label {
    color: #a94442 !important;
  }

  .is-invalid-label {
    color: #a94442;
  }

  .wpcf7-not-valid-tip, .wpcf7-display-none {
    display: none !important;
  }

  .wpcf7-response-output.wpcf7-mail-sent-ok.wpcf7-display-none {
    display: block !important;
  }

  .wpcf7-mail-sent-ok {
    background-color: #dff0d8;
    border: 2px solid #3c763d;
    color: #3c763d;
    padding: 1em 1em;
    margin: 2em 0;
  }

  button.button {
    margin-top: 0;

    @include breakpoint(large up) {
      float: right;
    }
  }

  &.sent {

    #hidecontactform7contactform {
      display: none !important;
    }

  }

}
