#video, .image.background {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  object-fit: fill;
  z-index: -1;
}

#video {
  @include breakpoint(medium up) {
    max-height: 700px;
  }
  @include breakpoint(xlarge up) {
    max-height: 1100px;
  }
}

.image.background {
  height: 100%;
  padding-top: 80px;

  @include breakpoint(medium up) {
    height: 100vh;
    padding-top: 103px;
  }

  img {
    @include breakpoint(medium down) {
      object-fit: contain;
      object-position: top;
    }

  }

}

.video-background {
  background: #000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
}

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
  .video-foreground {
    height: 300%;
    top: 0;
  }
}

@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    width: 300%;
    left: -100%;
  }
}

.section-image {
  margin-top: 1em;
  margin-bottom: 50px;
  position: relative;
  z-index: 9;

  .overlay-video {
    bottom: -30px;
    position: absolute;
    right: 0;

    @include breakpoint(medium up) {
      bottom: -50px;
    }

    @include breakpoint(large up) {
      right: -40px;
    }

    @include breakpoint(xlarge up) {
      bottom: -80px;
      right: -100px;
    }

    img {
      max-height: 100px;
      max-width: 150px;

      @include breakpoint(medium up) {
        max-height: 150px;
        max-width: 245px;
      }

      @include breakpoint(xlarge up) {
        max-height: 195px;
        max-width: 345px;
      }

    }

  }

  a {
    display: block;
    position: relative;

    .text-wrap {
      position: absolute;
      max-width: 60%;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 40%;
      transform: translateY(-50%);
      text-align: center;
      z-index: 99;


      @include breakpoint(xlarge up) {
        max-width: 70%;
      }


      @include breakpoint(xxxlarge up) {
        max-width: 60%;
      }

      .title {
        color: #ba844a;
        display: block;
        font-style: normal;
        font-family: 'Oswald', sans-serif;
        line-height: 120%;
        text-transform: uppercase;

        @include breakpoint(medium down) {
          font-size: 30px;
        }

        @include breakpoint(xlarge up) {
          font-size: 34px;
        }

        @include breakpoint(xxxlarge up) {
          font-size: 38px;
        }

      }

      .subtitle {
        color: #ffffff;
        display: block;
      }

    }

    &:before {
      background: $white;
      border-radius: 30px;
      color: #ef4035;
      content: "\f16a";
      display: inline-block;
      font-family: FontAwesome;
      font-size: 60px;
      height: 40px;
      left: 0;
      margin: 0 auto;
      top: 65%;
      line-height: 42px;
      position: absolute;
      right: 0;
      width: 50px;
      z-index: 99;
      transition: all 0.35s ease 0s;
      transform: translateY(-50%);
    }

    &:hover, &:focus {
      &:before {
        color: #d73930;
      }

    }

    .image {
      height: 395px;
      position: relative;

      &:before {
        background: rgba(0, 0, 0, 0.7);
        content: '';
        left: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 9;
      }

      @include breakpoint(large up) {
        height: 395px;
      }

      img {
        //filter: grayscale(100%);
      }

    }

    .overlay {
      bottom: 0;
      color: $white;
      display: block;
      left: 0;
      padding-bottom: 2.5em;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 99;

    }

  }

}