.contact-block {
  background: $white;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  //@include breakpoint(large up) {
  //  margin-top: 100px !important;
  //}

  .text {
    padding-top: 1em;
    padding-bottom: 1em;

    @include breakpoint(large up) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @include breakpoint(xlarge up) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    &.contactform {
      background: #181919;
      padding-left: 1em;

      @include breakpoint(large down) {
        padding-right: 1em;
        margin-bottom: 1em;
      }

      @include breakpoint(large up) {
        padding-left: 20px;

        &:after {
          background: #181919;
          content: "";
          left: 34%;
          position: absolute;
          right: 0;
          height: 100%;
          width: auto;
          top: 0;
          z-index: -1;
        }
      }

      @include breakpoint(xlarge up) {
        padding-left: 80px;

        &:after {
          left: 50%;
        }
      }
    }

    .data-overall {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .form {
      form {
        select, [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='file'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
          margin-bottom: 10px;
        }

        .button-wrap {
          float: initial;
          position: relative;
          width: 100%;

          @include breakpoint(large up) {
            float: right;
            margin-bottom: 10px;
            width: auto;
          }

          input {
            margin-bottom: 0;

            &.arrow {
              padding-right: 40px;
            }
          }


        }

      }
    }

  }

}
.contact {
  .background {
    z-index: 99;
  }
}
.contact-google-maps {
  margin-bottom: 1.5em;
  position: relative;
  z-index: 99;

  @include breakpoint(large up) {
    margin-bottom: 0;
  }

  @include breakpoint(xlarge up) {
    margin-top: 50px;
  }

  @include breakpoint(xxlarge up) {
    margin-top: 100px;
  }

  h4, h6 {
    font-weight: 400;
    margin-bottom: 0;
  }
}