.shopping-cart-block, .overview-shopping-cart, .multi-step-checkout-process {

  .title {
    h2 {
      margin-bottom: 0.3em;
    }

    h4 {
      font-weight: 400;
    }

    a {
      text-decoration: none;

      &[href^="tel"] {
        color: $primary-color !important;
      }

    }

  }

  h2 {
    margin-bottom: 0.5em;
  }

  p {
    line-height: 180%;

    + h2, + h3, + h4, + h5, + h6 {
      margin-top: 1em;
      margin-bottom: 0.5em;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

  }

  &.popup {
    .form {
      padding: 15px 20px;

      @include breakpoint(small down) {
        padding: 20px 5px;
      }

      h3 {
        color: $primary-color;

        span {
          color: $black;
        }

      }

      .data-overall {
        margin-bottom: 40px;

        ul.data {
          li {
            margin-bottom: 1em;

            span.price-total {
              color: $black;
              float: right;
              text-align: right;
              padding-left: 50px;
              position: relative;

              @include breakpoint(small down) {
                float: left;
                padding-left: 15px;
              }

              &:before {
                content: "€";
                left: 60px;
                position: absolute;
                top: 0;

                @include breakpoint(small down) {
                  left: 0;
                }
              }

            }
          }
        }

      }

      form {
        margin-bottom: 0;

        .side-order {

          .image-product {
            margin-bottom: 5px;
          }

          .text-wrap {
            h6 {
              margin-bottom: 1.5em;

            }
          }
        }

        table {
          tbody {
            tr {
              td {
                &.product-name {
                  width: 80%;
                  @include breakpoint(small down) {

                    .total-roundup {
                      margin-bottom: 0;

                      .product-name {
                        display: inline-block;
                      }

                    }

                  }
                }

                @include breakpoint(small down) {
                  border-bottom: none;
                  text-align: left;

                  &:first-of-type {
                    float: left;
                    padding-left: 15px;
                    width: 50%;
                  }
                  &:last-of-type {
                    width: 50%;
                  }

                  &:before {
                    display: none;
                  }
                }

                .total {
                  span {
                    @include breakpoint(small down) {
                      left: 50%;
                    }
                  }
                }

              }
            }
          }
        }

      }

    }
  }

  .form {
    background: $white;
    padding: 15px 15px;

    @include breakpoint(medium up) {
      padding: 15px 20px;
    }
    @include breakpoint(large up) {
      padding: 30px 40px;
    }

    .data-overall {
      margin-bottom: 1em;

      @include breakpoint(medium up) {
        margin-bottom: 40px;
      }

      @include breakpoint(large up) {
        margin-bottom: 80px;
      }

      ul {
        &.data {
          list-style: none;
          line-height: 170%;
          margin: 0;
          padding: 0;

          @include breakpoint(large down) {
            margin-bottom: 20px;
          }

          li {
            font-size: 16px;

            span {
              color: #898989;
              display: block;
              width: 100%;

              @include breakpoint(medium up) {
                display: inline-block;
                width: 200px;
              }
            }
          }

        }
      }
    }

  }

  .value-button {
    background: #ef4035;
    display: inline-block;
    border-right: 1px solid $white;
    color: $white;
    font-size: 20px;
    font-weight: bold;
    margin: 0px;
    width: 40px;
    height: 42px;
    text-align: center;
    padding: 2px 0 0;
    user-select: none;
    transition: all 0.35s ease 0s;

    &:hover {
      background: #e61f12;
      cursor: pointer;
      transition: all 0.35s ease 0s;
    }

    &.increase {
      border-radius: 0;
      float: left;
      margin-left: 1px;
    }

    &.decrease {
      margin-left: 0;
      border-radius: 0;
      left: 0;
      position: absolute;
    }
  }

  .numbers-row {
    display: inline-block;
    position: relative;

    input.addToShoppingCart2, input.addToShoppingCart {
      text-align: center;
      border: none;
      border-top: 1px solid #ddd;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      float: left;
      margin: 0 0 0 40px;
      width: 85px;
      height: 42px;

      @include breakpoint(medium down) {
        width: 45px;
        padding: 0.5rem 5px;
      }
    }

  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .text-wrap {
    ul.list {
      list-style: none;
      margin-bottom: 0.5em;
      margin-left: 0;
      padding-left: 0;
      text-align: left;

      li {
        line-height: 150%;
        position: relative;
        padding-left: 30px;
        @include breakpoint(medium up) {
          line-height: 200%;
        }

        span {
          margin-top: 0;
        }

        .check {
          position: absolute;
          left: 0;
          top: 2px;

          svg {
            height: 15px;
            width: 16px;

            path {
              fill: $secondary-color
            }
          }
        }
      }
    }
  }

  .select {
    padding-bottom: 40px;

    label {
      cursor: pointer;
    }

    input[type=checkbox] {
      position: absolute;
    }

    .text-wrap {
      display: block;
      padding: 0 20px;
      position: relative;

      .sort {
        color: $primary-color;
        font-weight: 700;
        font-family: 'Oswald', sans-serif;
        line-height: 120%;
        text-transform: uppercase;

        @include breakpoint(large up) {
          font-size: 18px;
        }

      }

    }

    .add-item {
      color: $primary-color;
      display: block;
      padding-bottom: 0;
      text-align: center;
    }

    .selected {
      color: #3cb878;
      font-size: 15px;
      opacity: 0;
      padding-left: 30px;
      position: relative;
      transition: all 0.3s ease 0s;

      @include breakpoint(small down) {
        float: left;
        margin: 10px 0 0 20px;
      }

      .icon {
        left: 0;
        position: absolute;
        top: 0;

        svg {
          height: 20px;
          width: 25px;
        }

        polygon {
          fill: #3cb878;
        }

      }
    }

    input[type=checkbox]:checked + span {
      background: #fff6ed;
      border-color: $primary-color;

      &:before {
        background: url('../../images/vink_checked.png') no-repeat center center;
      }

      & + span {

        & + span {
          color: $primary-color;
          display: none;
          transition: all 0.3s ease 0s;

          & + span {
            opacity: 1;
            transition: all 0.3s ease 0s;
          }
        }
      }

    }

    input[type=checkbox]:checked {

      & > .button {
        background-color: $secondary-color;
        border-color: $primary-color;
        color: $white;
      }

    }

    input[type=checkbox]:checked + span:after {
      display: none;
    }

    input[type=radio],
    input[type=checkbox] {
      /*hide the radio button*/
      filter: alpha(opacity=0);
      -moz-opacity: 0;
      -khtml-opacity: 0;
      opacity: 0;
    }

    select,
    input[type=text] {
      border-radius: 5px;
      padding: 6px 10px;
      border: 2px solid #9fc7c0;
      font-color: #666;
      font-size: 15px;
    }

  }

  .popular {
    margin-top: 30px;
    margin-bottom: 30px;
    @include breakpoint(large up) {
      margin-top: 60px;
      margin-bottom: 60px;
    }

    .side-order {

      .text-wrap {
        padding: 0 1em;
      }

      .image-product {
        display: table;
        height: 200px;
        overflow: hidden;
        margin-bottom: 20px;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 100%;

        @include breakpoint(small down) {
          float: left;
          height: 150px;
          margin-bottom: 0;
          width: 30%;
        }

        &:before {
          content: '';
          display: inline-block;
          height: 100%;
          vertical-align: middle;
          margin-right: -0.25em; /* Adjusts for spacing */
        }

        img {
          max-height: 175px;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          margin: 0 auto;
          transition: all 0.35s ease 0s;
          width: auto;
        }

      }

      input {
        &[type='submit'], &[type='button'] {
          background: none;
          border: none;
          color: $primary-color;
          cursor: pointer;

          &:hover {
            color: #e61f12;
          }

        }

      }

      select {
        background-color: #f7f7f7;
        box-shadow: inset 2px 2px 2px 0px rgba(222, 222, 222, 1);
        border: none;
        margin-bottom: 1em;
      }

    }

  }

  .no-products {
    p {
      color: #666;
      margin-bottom: 0;
      text-align: center;
    }
  }

  form {
    //@include breakpoint(medium up) {
    //  margin-bottom: 30px;
    //}
    //@include breakpoint(large up) {
    //  margin-bottom: 60px;
    //}

    h4 {
      margin: 1.5em 0 0.5em;
    }

    .input-group {
      margin-bottom: 5px;

      &.last {
        margin-bottom: 1.5em;
      }

      .has-tip {
        border-bottom: none;
        padding-left: 8px;
        padding-top: 3px;
        position: absolute;
      }

      .radiobuttons {
        display: inline-block;
        width: 100%;
        margin-bottom: 0.5em;

        label {
          color: $black;
          padding-left: 25px;
          display: block;
          width: 100%;
        }

        &.split {
          label {
            float: left;
            display: inline-block;
            width: 160px;
          }
        }


        @include breakpoint(large up) {
          &.split {
            display: inline-block;
            column-count: 2;
            column-gap: 70px;
            width: 100%;
          }
        }
      }

      label {
        color: #898989;
        float: left;
        font-size: 16px;
        line-height: 100%;
        padding-top: 10px;
        width: 155px;
      }

      .input-group {
        label {
          width: 325px;
        }
      }

      .group-multiple {
        width: 100%;

        .wrap-multiple {
          float: right;
        }

        label {
          text-align: right;
        }

        .small {
          display: inline-block;
          float: left;
          width: 100px;

          @include breakpoint(small down) {
            width: 100px;
          }

          input {
            width: 100px;
            @include breakpoint(small down) {
              width: 100px;
            }
          }
        }

        .smaller {
          display: inline-block;
          float: left;
          margin-left: 1em;
          width: 55px;
          @include breakpoint(small down) {
            margin-left: 0.5em;
            width: 60px;
          }

          input {
            width: 55px;
            @include breakpoint(small down) {
              width: 60px;
            }
          }
        }

      }

      .input-wrapper {
        width: 100%;

        select, [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='file'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
          background-color: #f7f7f7;
          box-shadow: inset 2px 2px 2px 0 rgba(222, 222, 222, 1);
          border: none;

          &::placeholder {
            color: #898989 !important;
            @include breakpoint(medium up) {
              color: #f7f7f7 !important;
            }
          }

        }

        input[type=date]::-webkit-inner-spin-button,
        input[type=date]::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }

      }

      input + span {
        box-shadow: inset 2px 2px 2px 0px rgba(182, 212, 230, 1);
        border: none !important;
        background: #f7f7f7;
        position: absolute;
        left: 0;
        top: 13px;
        width: 15px;
        height: 15px;
      }

      input[type=radio] + span {
        border-radius: 50%;
      }

      input[type=radio]:checked + span {
        box-shadow: inset 2px 2px 2px 0px rgba(170, 84, 36, 1);
        background: $primary-color;
      }

      input[type=checkbox]:checked + span:after {
        box-shadow: inset 2px 2px 2px 0px rgba(170, 84, 36, 1);
        background: $primary-color;
        color: #ffffff !important;
        content: "" !important;
        position: absolute;
        top: 0 !important;
        left: 0 !important;
        height: 15px;
        width: 15px;
      }

      input[type=radio], input[type=checkbox] {
        /*hide the radio button*/
        filter: alpha(opacity=0);
        opacity: 0;
      }

    }

    table {
      margin-bottom: 35px;
      font-size: 15px;
      overflow: hidden;

      @include breakpoint(small down) {
        margin-bottom: 20px;
      }

      thead {
        background: $white;
        border: none;

        th {
          color: $secondary-color;
          font-family: 'Oswald', sans-serif;
          font-weight: 400;
          font-size: 16px;
          text-transform: uppercase;
          padding: 5px 0 5px 35px;

          @include breakpoint(large down) {
            font-size: 15px;
            padding: 5px 0 5px 15px;
          }

          &:last-of-type {
            padding: 5px 15px 5px 5px;
          }

          &.product-price, &.product-quantity {
            text-align: right;
          }

          &.product-quantity {
            text-align: center;
          }

          &.product-subtotal {
            text-align: right;
            width: 10%;
          }

        }
      }

      tbody {
        border: none;

        tr {
          background: #f5f4f4;
          border-top: 1px solid $white;

          &:nth-child(even) {
            background: $white;
            @include breakpoint(small down) {
              background: #f5f4f4;
            }
          }

          &.free-product {
            td {
              color: $secondary-color;
              padding-top: 5px;
              padding-bottom: 5px;

              a {
                color: $secondary-color;
              }

            }
          }

          td {
            padding: 5px 0 5px 35px;

            @include breakpoint(large down) {
              padding: 5px 0 5px 15px;
              font-size: 15px;
            }

            @include breakpoint(small down) {
              display: inline-block;
              padding: 10px 15px;
              font-size: 15px;
              width: 100%;
            }

            ul {
              list-style: none;
              margin-bottom: 0;

              li {
                color: #666;
                font-style: italic;
                font-size: 14px;
              }

            }

            &:last-of-type {
              padding: 5px 15px 5px 5px;

              @include breakpoint(small down) {
                padding: 10px 15px;
              }

            }

            &.product-price {

              .amount {
                display: block;
                position: relative;
                text-align: right;

                span {
                  left: 0;
                  position: absolute;

                  @include breakpoint(small down) {
                    left: 70%;
                  }
                }

              }
            }

            &.product-subtotal {
              .total {
                display: block;
                position: relative;
                padding-left: 15px;
                text-align: right;

                span {
                  left: 0;
                  position: absolute;
                  @include breakpoint(small down) {
                    left: 70%;
                  }
                }

              }

            }

            &.product-price {
              @include breakpoint(medium up) {
                width: 15%;
              }
            }

          }

          &.subtotal-roundup,
          &.delivery-roundup,
          &.total-roundup {
            border: none;
            background: #362f2d;
            color: $white;

            td {
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 35px;

              @include breakpoint(large down) {
                padding-left: 15px;
              }

            }

          }

          &.subtotal-roundup, &.delivery-roundup {
            td {
              padding-top: 35px;
              padding-bottom: 5px;
              padding-left: 35px;

              @include breakpoint(large down) {
                padding-left: 15px;
              }
            }
          }

          &.delivery-roundup {

            td {
              padding-top: 5px;
              padding-bottom: 10px;
            }
          }

          &.total-roundup {
            border-top: 1px solid $white;
            color: $primary-color;
            font-weight: 700;
            font-family: 'Oswald', sans-serif;
            font-size: 16px;
            text-transform: uppercase;

            td {
              padding-bottom: 35px;
            }

          }

        }
      }

      tfoot {
        border: none;
      }

    }

    a {
      color: $black;

      &.button {
        color: $white;
      }

      &.remove {
        background: #ef4035;
        border: 0;
        border-radius: 100%;
        color: $white !important;
        display: block;
        float: right;
        font-size: 15px;
        font-weight: 700;
        padding-left: 1px;
        height: 15px;
        line-height: 1;
        width: 15px;
        text-align: center;
        text-decoration: none;

        @include breakpoint(small down) {
          font-size: 2.5em;
          line-height: 25px;
          height: 26px;
          position: absolute;
          right: 15px;
          width: 26px;
          top: 3px;
        }

        &:hover {
          background: #c13a31;
          color: #fff !important;
        }

      }
    }

    input[type='number'] {
      background-color: transparent;
      border: none;
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 2.4375rem;
      margin: 0;
      padding: .5rem 15px;
      box-shadow: none;
      font-family: inherit;
      font-size: 15px;
      font-weight: normal;
      line-height: 1.5;
      color: #0a0a0a;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        opacity: 1;
      }
    }

    .quantity {
      .qty {
        margin: 0 auto;
        text-align: center;
        width: 20%;
        @include breakpoint(large down) {
          width: 50%;
        }

        @include breakpoint(small down) {
          text-align: right;
        }
      }

      input[type='number'] {

        @include breakpoint(small down) {
          height: auto;
          float: right;
          padding: 0.5rem 5px;
        }
      }
    }

    .actions {
      float: left;
      display: inline-block;
      margin: 1em 0;

      @include breakpoint(medium up) {
        float: right;
        width: auto;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          float: left;
          margin-right: 2em;

          a {
            color: $primary-color;
            font-family: 'Oswald', sans-serif;
            font-weight: 700;
            font-size: 16px;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
              color: #e61f12;
            }

          }

        }

      }
    }

  }

  @include breakpoint(small down) {

    .quantity {
      .qty {
        display: inline-block;
        margin: 0.5em 0;
        width: 100%;
        text-align: right;
      }
    }

    .total-roundup, .subtotal-roundup, .delivery-roundup {
      margin-bottom: 0;

      .product-name, .product-thumbnail, .product-remove {
        display: none;
      }

    }

    table {
      border: 0;

      tbody {
        border: none;

        tr {
          border-left: 1px solid $white;
          border-right: 1px solid $white;
          //border-bottom: 1px solid $white;
          margin-bottom: 2em;

          @include breakpoint(small down) {
            margin-bottom: 0;
          }
        }

      }

    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      //border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0;
    }

    table td {
      //border-bottom: 1px solid #ddd;
      display: block;
      font-size: 14px;
      text-align: right;
    }

    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }

    table td:last-child {
      border-bottom: 0;

      @include breakpoint(small down) {
        display: inline-block;
        position: relative;
        width: 100%;
      }
    }
  }

  .button-wrap {
    float: left;
    position: relative;
    width: 100%;

    @include breakpoint(medium up) {
      float: right;
      width: auto;
    }

    input {
      margin-bottom: 0;

      &.arrow {
        padding-right: 40px;
      }
    }

    &.with-arrow {


      &:after {
        background-image: url('../../assets/img/pijltje.svg');
        background-size: 11px 15px;
        background-position: right center;
        content: "";
        display: block;
        height: 15px;
        position: absolute;
        right: 20px;
        width: 11px;
        top: 36%;
        z-index: 9;
        transition: all 0.3s ease 0s;
      }

    }

    .button {
      @include breakpoint(small down) {
        &:last-of-type {
          margin-top: 1em;
        }
      }
    }

  }

}

.overview-shopping-cart {
  background: transparent !important;

  .sidebar-content {
    background: $white;
    padding-bottom: 1em !important;

    p {
      color: $secondary-color;
      margin-bottom: 0;
    }
  }

  .form {
    padding: 0;

    p {
      padding-left: 30px;

      @include breakpoint(small down) {
        padding-left: 15px;
      }
    }


    form {
      margin-bottom: 0;
      padding-bottom: 35px;

      @include breakpoint(small down) {
        padding-bottom: 20px;
      }

      table {
        tr {
          td {
            padding: 0 0 0 35px;

            @include breakpoint(small down) {
              padding: 5px 15px 5px 15px;
            }

            &:first-of-type {

              @include breakpoint(medium up) {
                width: 65%;
              }
            }

            &.product-quantity {
              @include breakpoint(medium up) {
                padding-left: 0;
              }

              .qty {
                @include breakpoint(medium up) {
                  width: 75%;
                }
              }

            }

            a {
              color: $black;
              font-size: 15px;
            }

          }

          &.free-product {
            .product-name {
              @include breakpoint(small down) {
                display: none;
              }
            }

            .product-free {
              @include breakpoint(small down) {
                padding: 10px 15px 5px;
              }
            }

          }

          &.total-roundup td {
            padding-bottom: 12px;
          }
        }
      }

      .button-wrap {
        padding-left: 30px;
        padding-right: 30px;
        float: left;
        width: 100%;

        @include breakpoint(small down) {
          padding-left: 15px;
          padding-right: 15px;
        }

        input {
          width: 100%;
          text-align: left;
        }

        &:after {
          right: 50px;
        }

      }

    }

    #configure-buffet-table {
      table {
        tbody {

          @include breakpoint(small down) {
            tr {
              position: relative;

              td {

                &.product-name {
                  padding: 5px 15px 5px 25px;
                }

                &.product-remove {
                  position: absolute;
                  left: 0;
                  top: 0;

                  a {
                    &.remove {
                      position: absolute;
                      left: 15px;
                    }
                  }


                }
              }

            }
          }
        }
      }
    }

  }
}