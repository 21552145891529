body {

  @include breakpoint(xlarge down) {
    zoom: 85%;
  }

  @include breakpoint(large down) {
    zoom: 100%;
  }
}

.background {
  background: $black;
  left: 0;
  min-height: 100%;
  overflow: hidden;
  position: relative;
  right: 0;
  width: 100%;
  z-index: 99;

  @include breakpoint(medium down) {
    z-index: 999;
  }

  + section {
    margin-top: 1em;
    @include breakpoint(large up) {
      margin-top: 60px;
    }
    @include breakpoint(xlarge up) {
      margin-top: 80px;
    }

  }

  .overlay {
    background: url('../../assets/img/overlay.png') no-repeat left top;
    background-size: cover;
    left: 0;
    height: 300px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    width: 100%;
    @include breakpoint(medium up) {
      height: 100%;
      max-height: 700px;
    }
    @include breakpoint(xlarge up) {
      max-height: 1100px;
    }
  }

  section {
    margin-top: 135px;

    @include breakpoint(large up) {
      margin-top: 150px;
    }

    @include breakpoint(small down) {
      margin-top: 100px;
    }

    & > .title {
      color: $white;
      margin-bottom: 1em;
      text-align: center;

      h1, h2, h3, h4, h5, h6, p {
        color: $white;
        margin-bottom: 0.3em;
      }

      h3, h4, h5, h6 {
        font-weight: 400;
      }

    }

  }

  .image.home {
    @include breakpoint(medium down) {
      margin-top: 76px;
      height: 150px;
    }
  }

}

body {
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  //overflow-y: hidden;

  section {
    margin-bottom: 1em;
    position: relative;
    z-index: 9;

    @include breakpoint(large up) {
      margin-bottom: 60px;
    }

    @include breakpoint(xlarge up) {
      margin-bottom: 80px;
    }

    &.no-margin {
      margin-bottom: 0;
    }

  }

  p, ul, ol, li {
    font-family: 'PT Sans', sans-serif;
    font-size: 15px;
  }

  ul {
    @include breakpoint(large up) {
      &.split {
        display: inline-block;
        column-count: 2;
        column-gap: 70px;
        column-span: all;
        width: 100%;
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: $secondary-color;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 1em;
    text-transform: uppercase;
  }

  a {
    //text-decoration: underline;
    transition: all 0.3s ease 0s;

    &[href^="tel"] {
      color: $white !important;
      text-decoration: none;

      &:link, &:visited, &:hover {
        text-decoration: none;
        pointer-events: none;
        cursor: default;

        @include breakpoint(small down) {
          color: $primary-color !important;
          pointer-events: auto;
          cursor: pointer;
          text-decoration: underline;
        }

      }

    }

    &:hover, &:focus {
      text-decoration: none;
      transition: all 0.3s ease 0s;
    }

  }

  .button {
    background: #24853f;
    border: none;
    border-bottom: 3px solid #207739;
    //background: $primary-color;
    //border-bottom: 3px solid #d73930;
    color: $white;
    display: inline-block !important;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    margin-bottom: 0;
    padding: 18px 20px 15px;
    text-transform: uppercase;
    text-decoration: none;

    &#shoppingcartBtn {
      background: #ba844a;

      &:hover, &:focus {
        background: #dca366;
      }

    }

    &.arrow {
      padding-right: 40px;
      position: relative;
      width: auto;

      &::after {
        background-image: url('../../assets/img/pijltje.svg');
        background-size: 11px 15px;
        background-position: right center;
        content: "";
        display: block;
        height: 15px;
        position: absolute;
        right: 20px;
        width: 11px;
        top: 18px;
        z-index: 9;
        transition: all 0.3s ease 0s;
      }

      &.prev {
        padding-right: 20px;
        padding-left: 40px;

        &:after {
          background-image: url('../../assets/img/pijltje_left.svg');
          left: 20px;
          right: initial;
        }

      }

    }

    &:hover, &:focus {
      //border-bottom: 3px solid #d5281e;
      background: #00a651;
      border-bottom: 3px solid #009549;
    }

    &:first-of-type {
      margin-right: 1em;
    }
    &:last-of-type {
      margin-right: 0;
    }

    &.button-secondary {
      background: #24853f;
      border-bottom: 3px solid #207739;

      &:hover, &:focus {
        background: #00a651;
        border-bottom: 3px solid #009549;
      }

    }

    &.button-extra {
      background: #ef4035;
      border-bottom: 3px solid #d73930;

      &:hover, &:focus {
        background: #ff3225;
        border-bottom: 3px solid #ed261a;
      }

    }
  }

  .phone {
    a {
      color: $white;
      display: block;
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      font-size: 15px;
      .icon {
        display: inline-block;
        svg {
          width: 12px;

          path {
            fill: $white;
          }

        }
      }
    }
  }

  main {
    position: relative;
  }

  .benefits {
    color: #00a651;
    margin-top: 20px;

    @include breakpoint(large up) {
      margin-top: 35px;
    }


    ul.check {
      list-style: none;
      line-height: 200%;
      margin: 0;
      padding: 0;

      li {
        color: #00a651;
        font-weight: 400;
        font-family: 'Oswald', sans-serif;
        font-size: 15px;
        padding-left: 27px;
        position: relative;
        text-transform: uppercase;

        .icon {
          left: 0;
          position: absolute;
          top: 1px;

          svg {
            height: 15px;
            width: 20px;

            polygon {
              fill: #00a651;
            }

          }
        }

      }

    }

  }

  .data-overall {
    margin-bottom: 1em;

    @include breakpoint(medium up) {
      margin-bottom: 40px;
    }

    @include breakpoint(large up) {
      margin-bottom: 80px;
    }

    ul {
      &.data {
        list-style: none;
        line-height: 170%;
        margin: 0;
        padding: 0;

        li {
          font-size: 16px;

          span {
            color: #898989;
            display: inline-block;
            width: 100px;

            @include breakpoint(medium up) {
              width: 200px;
            }

            @include breakpoint(large up) {
              width: 85px;
            }

            @include breakpoint(xlarge up) {
              width: 200px;
            }
          }
        }

      }
    }
  }

}
.callout.success {
  background-color: #e1f6de;
  border: none;

  .benefits ul.check li {
    font-family: 'PT Sans', sans-serif;
    padding-left: 35px;
    text-transform: initial;
  }

}
