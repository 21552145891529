.side-order {
  background: $white;
  height: auto;
  margin: 0;
  padding: 0;

  @include breakpoint(medium up) {
    padding: 0 20px;
  }

  &.roundup {
    margin-bottom: 2em;
    padding: 0;
  }

  .grid-x {
    .cell {
      &:first-of-type {
        .item {
          padding-left: 0;
        }
      }
    }
  }

  .item {
    position: relative;

    @include breakpoint(medium up) {
      padding: 0 10px;
    }

    .label {
      background: $primary-color;
      color: $white;
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      font-size: 13px;
      left: 10px;
      padding: 7px 10px;
      position: absolute;
      top: 0;
      text-transform: uppercase;
      z-index: 9;
    }

  }

  .image {
    position: relative;
    height: 200px;

    @include breakpoint(medium up) {
      margin-bottom: 18px;
    }

    @include breakpoint(small down) {
      float: left;
      height: 130px;
      width: 30%;
    }

    .corner {
      bottom: 0;
      position: absolute;
      right: 0;
      border-style: solid;
      border-width: 0 0 40px 40px;
      border-color: transparent transparent #ffffff transparent;
      z-index: 6;
    }

    .icon {
      bottom: 1px;
      border-bottom: none;
      position: absolute;
      right: 1px;
      z-index: 7;
    }

  }

  .text-wrap {
    padding: 0 20px;

    @include breakpoint(large up) {
      padding: 0 35px;
    }

    @include breakpoint(small down) {
      float: left;
      padding: 0 10px;
      position: relative;
      width: 70%;
      text-align: left;
    }

    h6 {
      color: $primary-color;
      margin-bottom: 0.2em;

      @include breakpoint(medium up) {
        min-height: 65px;
      }
    }

    .price {
      margin-bottom: 10px;

      @include breakpoint(medium up) {
        margin-bottom: 18px;
      }

      span {
        color: #a1a1a1;
      }
    }

    input {
      &[type='submit'], &[type='button'] {
        background: none;
        border: none;
        color: $primary-color;
        cursor: pointer;

        &:hover {
          color: #e61f12;
        }

      }

    }

    select {
      background-color: #f7f7f7;
      box-shadow: inset 2px 2px 2px 0px rgba(222, 222, 222, 1);
      border: none;
      margin-bottom: 0.5em;

      @include breakpoint(medium up) {
        margin-bottom: 1em;
      }
    }

  }

}