/***
	FOOTER
***/
footer {
  background: $black;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;

  @include breakpoint(medium down) {
    margin: 0;
    padding: 0;
  }

  .text-center {
    @include breakpoint(medium down) {
      text-align: left;
    }
  }

  h4, h5, h6 {
    color: $white !important;
    line-height: 150% !important;
    margin-bottom: 0 !important;

    @include breakpoint(medium up) {
      line-height: 120% !important;
      font-size: 25px;
    }

    @include breakpoint(large up) {
      font-size: 30px;
    }


    a {
      color: $primary-color;
      text-decoration: none;

      &[href^="tel"] {
        color: $primary-color !important;
        text-decoration: none;

        &:link, &:visited, &:hover {
          text-decoration: none;
          pointer-events: none;
          cursor: default;

          @include breakpoint(small down) {
            color: $primary-color !important;
            pointer-events: auto;
            cursor: pointer;
          }

        }

      }

      &:hover, &:focus {
        color: $white;
      }

    }

  }

  ul li {
    @include breakpoint(small down) {
      font-size: small;
    }
  }

  .copyright {
    background: url('../../assets/img/background_footer.png') no-repeat center top;
    background-size: cover;
    display: block;
    justify-content: left;
    text-align: left;
    //margin-bottom: 60px;
    padding: 40px 0 0;
    position: relative;
    width: 100%;

    @include breakpoint(medium down) {
      display: block;
      text-align: left;
      justify-content: flex-start;
      margin-bottom: 0;
      padding: 30px 0 30px;
    }

    .footer-persona {
      img {
        max-height: 390px;
      }
    }

    ul {
      list-style: none;
      margin: 2em 0 0;
      padding: 0;
      width: 100%;

      @include breakpoint(medium up) {
        display: flex;
        justify-content: left;
        text-align: left;
      }

      @include breakpoint(large up) {
        margin: 45px 0 0;
      }

      + ul {
        margin-top: 0;
      }

      &.social-media {
        margin: 2em 0 0;

        @include breakpoint(large up) {
          margin: 45px 0 0;
        }

        li {
          display: inline-block;
          float: left;
          margin-right: 10px;
          padding: 0 10px 0 0;

          &:after {
            content: none;
          }

          a {
            background: transparent;
            color: $white;
            border-radius: 50%;
            display: table-cell;
            height: 30px;
            line-height: inherit;
            width: 30px;
            text-align: center;
            vertical-align: middle;
            transition: all 0.35s ease 0s;

            i {
              display: inline-block;
              vertical-align: middle;
              line-height: 30px;
              padding: 1px 0 0 1px;
            }

            &:hover, &:focus {
              background: transparent;
            }

          }

          &:after {
            content: none;
            display: none;
          }

        }
      }

      li {
        color: $secondary-color;
        float: left;
        font-size: 15px;
        font-weight: 300;
        padding: 0 10px 0 0;

        @include breakpoint(medium down) {
          float: none;
          text-align: left;
        }

        @include breakpoint(small down) {
          font-size: small;
        }

        a {
          color: $secondary-color;
          text-decoration: none;
          transition: all 0.35s ease 0s;

          &:hover, &:focus {
            color: $primary-color;
          }

        }

        &:after {
          content: '-';
          padding: 0 0 0 10px;

          @include breakpoint(medium down) {
            content: none;
          }

        }

        &:last-of-type {

          &:after {
            content: none;
          }

        }

      }

    }

  }

}