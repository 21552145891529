/**
* Create a circular shaped image
*/
.image-circle {
  @include border-radius(50%);
}

/**
* Makes image responsive
*/
.image-responsive {
  width: 100%;
  height: auto;
}

/**
* Image with rounded corners
*/
.image-rounded {
  @include border-radius(0.8rem);
}

/**
* For retina images
*/
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

/**
* Cover image
*/
.image {
  display: block;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
    object-position: center; /* Center the image within the element */
    max-width: initial;
    position: relative; /* allows repositioning */
    width: 100%;
    height: 100%;
    transition: all .35s ease;
  }

}

/* CSS Fallback from Modernizr */
.image.compat-object-fit {
  background-size: cover;
  background-position: center;
  img {
    opacity: 0;
  }
}