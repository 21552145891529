// Colors
$white: #fff;
$black: #000;
$gray: #616161;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction: column;
}

.title {
  font-size: 38px;
  color: $gray;
  font-style: italic;
  font-weight: 800;
}

// Timeline
.timeline {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 25px 5px rgba($black, .2);
  position: relative;


  h2 {
    @include breakpoint(small down) {
      margin-bottom: 0.5em;
    }
  }

  .scroll-to-content {
    position: absolute;
    bottom: 2em;
    z-index: 9;

    &.scroll-up {
      left: 1em;
    }

    &.scroll-down {
      right: 1em;
    }

    a {
      color: $white;
      font-family: 'Oswald', sans-serif;
      text-transform: uppercase;

      &:hover {
        color: $primary-color;
      }

    }

  }

  .swiper-container {
    height: 685px;
    width: 100%;
    position: relative;

    @include breakpoint(xlarge up) {
      height: 875px;
    }

    .title {
      left: 0;
      position: absolute;
      text-align: center;
      right: 0;
      top: 35px;
      z-index: 9;
    }

  }

  .swiper-wrapper {
    transition: 2s cubic-bezier(.68, -0.4, .27, 1.34) .2s;
  }

  .swiper-slide {
    position: relative;
    color: $white;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-overflow-scrolling: touch;


    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      right: 0;
      background-color: rgba($black, .9);
    }

    &-content {
      position: absolute;
      text-align: center;
      width: 85%;
      right: 50%;
      top: 13%;
      transform: translate(50%, 0);
      font-size: 12px;
      z-index: 2;

      p {
        @include breakpoint(small down) {
          font-size: 14px;
        }
      }

    }

    .image-wrapper {
      display: block;
      overflow: hidden;
      margin-bottom: 2em;
      position: relative;

      @include breakpoint(small down) {
        margin: 0 auto 1em;
        width: 70%;
      }

      .frame {
        display: block;
        left: 0;
        overflow: hidden;
        position: relative;
        top: 0;
        z-index: 9;
        width: 100%;
      }

      .image {
        display: block;
        height: 90%;
        left: 2%;
        position: absolute;
        top: 5%;
        width: 96%;
      }

    }


    .timeline-year {
      color: $secondary-color;
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      display: block;
      font-size: 15px;
      margin-bottom: 5px;
      transform: translate3d(20px, 0, 0);
      opacity: 0;
      transition: .2s ease .4s;
    }

    .timeline-title {
      font-weight: 700;
      font-size: 30px;
      margin: 0 0 0.2em;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .5s;

      @include breakpoint(small down) {
        font-size: 18px;
      }
    }

    .timeline-text {
      line-height: 200%;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .6s;

      @include breakpoint(small down) {
        line-height: 150%;
      }
    }

    &-active {
      .timeline-year {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.6s;
      }

      .timeline-title {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.7s;
      }

      .timeline-text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.8s;
      }
    }
  }

  .swiper-pagination {
    right: 15% !important;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      left: -30px;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: $white;
    }

    &-bullet {
      font-family: 'Oswald', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 15px;
      width: auto;
      height: auto;
      text-align: center;
      opacity: 1;
      background: transparent;
      color: $white;
      margin: 15px 0 !important;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 4px;
        left: -32.5px;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        border: 1px solid $white;
        background-color: $white;
        transform: scale(1);
        transition: .2s;
      }

      &-active {
        color: $primary-color;

        &::before {
          border: 1px solid $black;
          background-color: $primary-color;
          transform: scale(1);
        }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-size: 20px 20px;
    //display: none;
    top: 40%;
    width: 20px;
    height: 20px;
    margin-top: 0;
    z-index: 2;
    transition: .2s;
  }

  .swiper-button-prev {
    left: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover {
      transform: translateX(-3px);
    }
  }

  .swiper-button-next {
    right: 8%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E");

    &:hover {
      transform: translateX(3px);
    }
  }

  @include breakpoint(medium up) {

    .swiper-container {
      height: 875px;

      .title {
        top: 65px;
      }

    }

    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }

    .swiper-slide {

      &-content {
        left: 17%;
        top: 50%;
        transform: translateY(-50%);
        font-size: 11px;
        text-align: left;
      }

      .timeline-year {
        margin-bottom: 0;
      }

      .timeline-title {
        font-size: 30px;
      }
    }

    .swiper-pagination {
      left: 4% !important;
      display: flex;
      right: initial !important;

      &::before {
        right: -29px !important;
        left: initial;
      }

    }

    .swiper-pagination-bullet {
      &::before {
        left: initial;
        right: -35.5px;
      }
    }

    .swiper-button-prev {
      top: 15%;
      right: auto;
      left: 10%;
      transform: rotate(90deg) translate(0, 10px);

      &:hover {
        transform: rotate(90deg) translate(-3px, 10px);
      }
    }

    .swiper-button-next {
      top: auto;
      bottom: 15%;
      left: 10%;
      transform: rotate(90deg) translate(0, 10px);

      &:hover {
        transform: rotate(90deg) translate(3px, 10px);
      }
    }
  }

  @include breakpoint(large up) {

    .swiper-container {
      height: 650px;
    }

    .image-wrapper {
      margin-bottom: 0;
    }

    .swiper-slide {

      &-content {
        left: 15%;
      }
    }
  }

  @include breakpoint(xlarge up) {

    .swiper-container {
      height: 875px;
    }

    .swiper-pagination {
      left: 9% !important;
    }
  }
}





