.reveal-overlay {
  background: url('../../assets/img/bg_menu.png') no-repeat top center $black;
  background-size: contain;
  z-index: 9999;

  .close-button {
    color: $black;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    font-size: 15px;
    padding-left: 21px;
    text-transform: uppercase;
    top: 15px;
    z-index: 999;
    
    span {
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      font-size: 36px;
      left: 0;
      position: absolute;
      top: -11px;
    }

  }

  .reveal {
    @include breakpoint(small down) {
      -webkit-overflow-scrolling : touch !important;
      overflow: auto !important;
      min-height: 100% !important;
      padding-bottom: 100px;
    }
  }

}